import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';
import App from './App';
import Home from './components/Home/Home';
import Meetings from './components/Meetings/Meetings';
import Meeting from './components/Meetings/Meeting';
import Events from './components/Events/Events';
import Donate from './components/Donate/Donate';
import HandI from './components/HandI/HandI';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Steps from './components/About/Steps';
import Copyright from './components/Copyright/Copyright';
import Privacy from './components/Privacy/Privacy';
import Literature from './components/Literature/Literature';
import WeCanRecover from './components/Literature/WeCanRecover';
import TheTwelveTraditions from './components/Literature/TheTwelveTraditions';
import WhoIsACocaineAddict from './components/Literature/WhoIsACocaineAddict';
import WhoIsACAMember from './components/Literature/WhoIsACAMember';
import ThePromises from './components/Literature/ThePromises';
import AVisionForYou from './components/Literature/AVisionForYou';
import ReachingOut from './components/Literature/ReachingOut';
import SerenityPrayer from './components/Literature/SerenityPrayer';
import CAPreamble from './components/Literature/CAPreamble';
import AndAllOtherMindAlteringSubstances from './components/Literature/Pam/AndAllOtherMindAlteringSubstances';
import TwelvePrinciples from './components/Literature/Pam/TwelvePrinciples';
import GuideToThe12Steps from './components/Literature/Pam/GuideToThe12Steps';
import AHigherPower from './components/Literature/Pam/AHigherPower';
import AMessageToProfessionals from './components/Literature/Pam/AMessageToProfessionals';
import ANewHighFromHI from './components/Literature/Pam/ANewHighFromHI';
import Anonymity from './components/Literature/Pam/Anonymity';
import BeingAGSR from './components/Literature/Pam/BeingAGSR';
import BeingOfService from './components/Literature/Pam/BeingOfService';
import CAIsAlsoForTheLGTQPlusAddict from './components/Literature/Pam/CAIsAlsoForTheLGTQPlusAddict';
import CALifeline from './components/Literature/Pam/CALifeline';
import ChoosingYourSponsor from './components/Literature/Pam/ChoosingYourSponsor';
import CocaineAnonymousSelfTest from './components/Literature/Pam/CocaineAnonymousSelfTest';
import Crack from './components/Literature/Pam/Crack';
import DosDontsFor12thStepCallsForAddicts from './components/Literature/Pam/DosDontsFor12thStepCallsForAddicts';
import How from './components/Literature/Pam/How';
import HavingFunInRecovery from './components/Literature/Pam/HavingFunInRecovery';
import ReachingOutToTheDeafAndHardOfHearing from './components/Literature/Pam/ReachingOutToTheDeafAndHardOfHearing';
import The7thTradition from './components/Literature/Pam/The7thTradition';
import TheFirst30Days from './components/Literature/Pam/TheFirst30Days';
import TheHomeGroup from './components/Literature/Pam/TheHomeGroup';
import TipsForStayingCleanSober from './components/Literature/Pam/TipsForStayingCleanSober';
import ToTheNewcomer from './components/Literature/Pam/ToTheNewcomer';
import TooYoungToRecover from './components/Literature/Pam/TooYoungToRecover';
import ToolsOfRecovery from './components/Literature/Pam/ToolsOfRecovery';
import Unity from './components/Literature/Pam/Unity';
import WhatIsCA from './components/Literature/Pam/WhatIsCA';
import YesYouCanStartACAMeeting from './components/Literature/Pam/YesYouCanStartACAMeeting';
import PI from './components/PI/PI';

// import AppRouter from './AppRouter';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="meetings" element={<Meetings />} />
          <Route path="meetings/:id" element={<Meeting/>} />
          <Route path="events" element={<Events/>} />
          <Route path="donate" element={<Donate />} />
          <Route path="hi" element={<HandI/>} />
          <Route path="pi" element={<PI/>} />
          <Route path="literature" element={<Literature/>} />
          <Route path="literature/we_can_recover" element={<WeCanRecover/>} />
          <Route path="literature/the_twelve_traditions" element={<TheTwelveTraditions/>} />
          <Route path="literature/who_is_a_cocaine_addict" element={<WhoIsACocaineAddict/>} />
          <Route path="literature/who_is_a_ca_member" element={<WhoIsACAMember/>} />
          <Route path="literature/the_promises" element={<ThePromises/>} />
          <Route path="literature/a_vision_for_you" element={<AVisionForYou/>} />
          <Route path="literature/reaching_out" element={<ReachingOut/>} />
          <Route path="literature/serenity_prayer" element={<SerenityPrayer/>} />
          <Route path="literature/ca_preamble" element={<CAPreamble/>} />
          <Route path="literature/and_all_other_mind_altering_substances" element={<AndAllOtherMindAlteringSubstances/>} />
          <Route path="literature/12_principles" element={<TwelvePrinciples/>} />
          <Route path="literature/guide_to_the_12_steps" element={<GuideToThe12Steps/>} />
          <Route path="literature/a_higher_power" element={<AHigherPower/>} />
          <Route path="literature/a_message_to_professionals" element={<AMessageToProfessionals/>} />
          <Route path="literature/a_new_high_from_hi" element={<ANewHighFromHI/>} />
          <Route path="literature/anonymity" element={<Anonymity/>} />
          <Route path="literature/being_a_gsr" element={<BeingAGSR/>} />
          <Route path="literature/being_of_service" element={<BeingOfService/>} />
          <Route path="literature/ca_is_also_for_the_lgbtq_plus_addict" element={<CAIsAlsoForTheLGTQPlusAddict/>} />
          <Route path="literature/ca_lifeline" element={<CALifeline/>} />
          <Route path="literature/choosing_your_sponsor" element={<ChoosingYourSponsor/>} />
          <Route path="literature/cocaine_anonymous_self_test" element={<CocaineAnonymousSelfTest/>} />
          <Route path="literature/crack" element={<Crack/>} />
          <Route path="literature/dos_donts_for_12th_step_calls_for_addicts" element={<DosDontsFor12thStepCallsForAddicts/>} />
          <Route path="literature/how" element={<How/>} />
          <Route path="literature/having_fun_in_recovery" element={<HavingFunInRecovery/>} />
          <Route path="literature/reaching_out_to_the_deaf_and_hard_of_hearing" element={<ReachingOutToTheDeafAndHardOfHearing/>} />
          <Route path="literature/the_7th_tradition" element={<The7thTradition/>} />
          <Route path="literature/the_first_30_days" element={<TheFirst30Days/>} />
          <Route path="literature/the_home_group" element={<TheHomeGroup/>} />
          <Route path="literature/tips_for_staying_clean_sober" element={<TipsForStayingCleanSober/>} />
          <Route path="literature/to_the_newcomer" element={<ToTheNewcomer/>} />
          <Route path="literature/too_young_to_recover" element={<TooYoungToRecover/>} />
          <Route path="literature/tools_of_recovery" element={<ToolsOfRecovery/>} />
          <Route path="literature/unity" element={<Unity/>} />
          <Route path="literature/what_is_ca" element={<WhatIsCA/>} />
          <Route path="literature/yes_you_can_start_a_ca_meeting" element={<YesYouCanStartACAMeeting/>} />
          <Route path="about" element={<About/>} />
          <Route path="12and12" element={<Steps/>} />
          <Route path="contact" element={<Contact/>} />
          <Route path="copyright" element={<Copyright/>} />
          <Route path="privacy" element={<Privacy/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
