import React from 'react'

const TheTwelveTraditions = () => (
    <section className="section">
        <div className="container" >
            <h1 className="title" > The Twelve Traditions </h1>
            <div className="columns">
                <div className="column is-8">
                    <div className="box has-background-white content">
                        <p>
                            <ol type="1">
                                <li>Our common welfare should come first; personal recovery depends upon C.A. unity.</li>
                                <li>For our group purpose there is but one ultimate authority—a loving God as He may
                                express Himself in our group conscience. Our leaders are but trusted servants; they
                                do not govern.</li>
                                <li>The only requirement for C.A. membership is a desire to stop using cocaine and all
                                other mind-altering substances.</li>
                                <li>Each group should be autonomous except in matters affecting other groups or C.A.
                                as a whole.</li>
                                <li>Each group has but one primary purpose—to carry its message to the addict who
                                still suffers.</li>
                                <li>A C.A. group ought never endorse, finance, or lend the C.A. name to any related
                                facility or outside enterprise, lest problems of money, property and prestige divert us
                                from our primary purpose.</li>
                                <li>Every C.A. group ought to be fully self-supporting, declining outside contributions.</li>
                                <li>Cocaine Anonymous should remain forever nonprofessional, but our service centers
                                may employ special workers.</li>
                                <li>C.A., as such, ought never be organized; but we may create service boards or
                                committees directly responsible to those they serve.</li>
                                <li>Cocaine Anonymous has no opinion on outside issues; hence the C.A. name ought
                                never be drawn into public controversy.</li>
                                <li>Our public relations policy is based on attraction rather than promotion; we need always maintain personal anonymity at the level of all public media.</li>
                                <li>Anonymity is the spiritual foundation of all our Traditions, ever reminding us to place
                                principles before personalities.</li>
                            </ol>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default TheTwelveTraditions


