import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Modal from "../Modal/Modal";
// import eventdata from "../Events/events-data";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "",
      modalContent: "",
      modalShow: false,
      allEvents : [],
      upcomingEvents: []
    };
  }

  componentDidMount() {
      //get events data
      fetch('/data/events-data.json'
        ,{
          headers: {
            'Context-Type' : 'application/json'
          }
        })
      .then(response => {
        return response.json();
      })
      .then(eventsJson => {
        //sort by date
        let sortedEvents = this._getUpcomingEvents(eventsJson).sort((a,b) => {
          let dateA = ''
          let dateB = ''
          //either DateTimeStart or Date
          if(a['DateTimeStart'])
            dateA = new Date(a['DateTimeStart']);
          else
            dateA = new Date(a['Date']);

          if(b['DateTimeStart'])
            dateB = new Date(b['DateTimeStart']);
          else
            dateB = new Date(b['Date']);

          if(dateA < dateB)
            return -1;
          if(dateA > dateB)
            return 1;
          
          return 0;
        });

        this.setState({
          upcomingEvents: sortedEvents
        })

      })
  }



  _getUpcomingEvents = (events) => {

    let now =  moment();
    let nextFive = moment().add(30, 'd');

    let upcomingEvents = events.filter((event) => {
      //search DateTimeStart or Date
      if(event.DateTimeStart){
        if(event.DateTimeEnd){
          return moment(event.DateTimeEnd).isBetween(now, nextFive) !== false;
        }else {
          return moment(event.DateTimeStart).isBetween(now, nextFive) !== false;
        }
      }
      else
        return moment(event.Date).isBetween(now, nextFive) !== false;

    });

    return upcomingEvents;
  };

  _setModal = (img, type, e) => {
    e.preventDefault();
    this.setState({
      modalType: type,
      modalContent: img,
    });
    this._toggleModal();
  };

  _toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalShow;
      return { modalShow: newState };
    });
  };

  _openLink = () => {
    this._toggleModal();
    window.open(this.state.modalContent, "_blank");
  };

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">
                    Welcome to Cocaine Anonymous New York
                  </p>
                  <p>
                  Cocaine Anonymous is a fellowship of men and women who share their experience, strength and hope with each other that they may solve their common problem and help others to recover from their addiction.
                  </p>
                  <p>
                  The only requirement for membership is a desire to stop using cocaine and all other mind‐ altering substances.There are no dues or fees for membership; we are fully self‐ supporting through our own contributions.
                  </p>
                  <p>
                  We are not allied with any sect, denomination, politics, organization or institution.We do not wish to engage in any controversy, and we neither endorse nor oppose any causes. 
                  </p>
                  <p>Our primary purpose is to stay free from cocaine and all other mind‐ altering substances and to help others achieve the same freedom.</p>
                  <p>We use the Twelve‐ Step recovery Program because it has already been proven that the Twelve‐ Step Recovery Program works.</p>
                  <br></br>
                  <div className="divider"></div>
                  <p>
                    I'm concerned about my drinking or drug use.<br></br>
                    <Link to="/literature/cocaine_anonymous_self_test">Click here</Link> if you are looking for help with a drink or drug problem.
                  </p>
                  <br></br>
                  <div className="divider"></div>
                  <p>
                    Find a <Link to="/meetings">meeting</Link> or call our <a href="tel:212-262-2463">helpline</a>
                  </p>
                </article>
              </div>
            </div>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">
                    CANY Area
                  </p>
                  <p>The Area serves the Fellowship by distributing chips and literature, handling financial contributions of the Fellowship for that Area, and communicating with the C.A. World Service Office. The primary function of the Area is to serve the common needs of its District Members and to facilitate unification. </p>
                  <p>
                    Are you a GSR? All CA meetings should have a elected GSR.</p>
                  <p><br></br>
                    Area meets the 2nd Wednesday of each month at 7:00 PM
                    online. <br /><br />
                    <a
                      href="0"
                      onClick={(e) =>
                        this._setModal(
                          "https://us02web.zoom.us/j/82360875449?pwd=enVIWEVIakFlOHVEZkpzdFVKbFR0UT09",
                          "leaving",
                          e
                        )
                      }
                    >
                      Join Zoom Meeting
                      
                    </a>{" "}
                    <br />
                    Meeting ID: 823 6087 5449 <br />
                    Password: 937280
                  </p>
                </article>
              </div>
              <div className="tile is-parent">
              <article className="tile is-child box">
                <p className="subtitle">
                    New Meeting Announcement
                  </p>
                  <p>
                  Meeting name: <u>Second Chances</u><br></br>
                    Saturdays at 3:00PM EST <br></br>
                    Format: Rotating Format
                  </p>
                  <p>
                    <br />
                    1775 East Avenue Rochester New York  <br />
                    Note : Please use the back door by the dumpster <br></br><br></br>
                  </p>
                  <p>
                  Meeting name: <u>PowderPuff Girls Women's Meeting</u><br></br>
                    Saturdays at 11:30AM EST <br></br>
                    Format: Rotating Format
                  </p>
                  <p>
                    <br />
                    Mustard Seed - 122 E 37th Street, basement, back room  <br />
                  </p>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Modal
          type={this.state.modalType}
          content={this.state.modalContent}
          closeModal={this._toggleModal}
          openLink={this._openLink}
          modalState={this.state.modalShow}
        />
      </React.Fragment>
    );
  }
}

export default Home;
