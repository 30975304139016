import React from 'react'
import Modal from '../Modal/Modal';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: '',
      modalContent: '',
      modalShow: false
    };
  }

  _setModal = (img, type, e) => {
    e.preventDefault();
    this.setState({
      modalType: type,
      modalContent: img,
    })
    this._toggleModal();
  }

  _toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalShow;
      return { modalShow: newState }
    });
  }

  _openLink = () => {
    this._toggleModal();
    window.open(this.state.modalContent, "_blank");
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container" >
            <h1 className="title" > Contact Us </h1>
            <div className="box has-background-white content">
            <div className="columns">
              <div className="column">
                <p className="subtitle">We're here &amp; we're free! </p>
                <img alt="together" src="/img/pic8_1.jpg"></img>
                <p>
                  C.A. is concerned solely with the personal recovery and continued sobriety of individual drug addicts who turn to our Fellowship for help.            </p>
                <p>
                  We do not engage in the fields of drug addiction research, medical or psychiatric treatment, drug education, or propaganda in any form - although members may participate in such activities as individuals.</p>
                <p>
                  Cocaine Anonymous New York, Inc. <br />
                  244 Madison Avenue #1530<br />
                New York, New York 10016<br />
                Telephone: (212) COCAINE <br />
                </p>
              </div>
              <div className="column">
                <p className="subtitle">CANY Area Service Committee</p>
                <p>
                  Website Updates <br />
                  <a href="mailto:canewyorkwebsite@gmail.com">canewyorkwebsite@gmail.com</a>
                </p>
                <p className="subtitle">Contact CA World Services</p>
                <p>
                  You can contact the C.A. World Services Office by phone 9:00 am to 5:00 pm, Monday through Friday (U.S. Pacific Time; GMT -0800)
                </p>
                <p>
                  CAWSO <br />
                  11460 N. Cave Creek Road, Suite 12  <br />
                  Phoenix, AZ 85020 <br />
                Telephone:  (310) 559-5833 <br />
              
                Email: <a href="mailto:cawso@ca.org">cawso@ca.org</a><br />
                </p>
                <p>
                </p>
              </div>
              <div className="column">
                <p className="subtitle">CA World Services Links</p>
                <p>
                  <a href="0" onClick={(e) => this._setModal('https://ca.org/service/', 'leaving', e)}>World Service Manual</a>
                </p>
                <p>
                  <a href="0" onClick={(e) => this._setModal('https://ca.org/contact-us/request-more-info-starter-kit/', 'leaving', e)}>Request a Meeting Starter Kit, or get more information about C.A.</a>
                </p>
                <p>
                  <a href="0" onClick={(e) => this._setModal('https://ca.org/events/submit-your-ca-event/', 'leaving', e)}>Submit your local C.A. event information to be added to the C.A. Events page.</a> Events must meet C.A. sanctioned event guidelines as listed.
                </p>
                <p>
                  <a href="0" onClick={(e) => this._setModal('https://ca.org/contact-us/', 'leaving', e)}>Add or change your Area or Regional information to be added to the Local Phones and Websites page.</a> </p>
                <p>
                  <a href="0" onClick={(e) => this._setModal('https://ca.org/contact-us/', 'leaving', e)}>Contact the C.A. World Service Office Board</a>
                </p>
                <p>
                  <a href="0" onClick={(e) => this._setModal('https://ca.org/contact-us/contact-wsbt/', 'leaving', e)}>Contact the C.A. World Service Board of Trustees to submit your feedback and comments.</a>
                </p>
              </div>
            </div>
            </div>
          </div >
        </section>
        <Modal type={this.state.modalType} content={this.state.modalContent} closeModal={this._toggleModal} openLink={this._openLink} modalState={this.state.modalShow} />
      </React.Fragment>
    );
  }
}

export default Contact;